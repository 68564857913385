import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import SectionIntro from "@components/SectionIntro";
import AgentItem from "@components/AgentItem";
function AgentsSelector(props) {
    const ERROR_MESSAGE_NO_AGENTS_TEXT = "There are no agents available";
    const AGENT_SELECTOR_ITEM_LINK_LABEL = (_jsxs(_Fragment, { children: ["Show agent", _jsx("svg", { className: "icon icon--lock", children: _jsx("use", { "xlink:href": "#lock" }) })] }));
    const { onSelected, field: { name }, form: { setFieldValue }, sectionIntro, agents } = props;
    const handleButtonClick = (newValue) => {
        setFieldValue(name, newValue);
        onSelected();
    };
    const renderAgentItem = (agent) => (_jsx(AgentItem, { agent: agent, allowHighlight: false, children: _jsx("button", { type: "button", name: name, value: agent.id, className: "btn btn--secondary", onClick: () => handleButtonClick(agent.id), children: AGENT_SELECTOR_ITEM_LINK_LABEL }) }));
    if (!agents) {
        // Return message for no agents
        return _jsx("div", { className: "form__group", children: ERROR_MESSAGE_NO_AGENTS_TEXT });
    }
    return (_jsxs("div", { className: "form__group form__group--full-width", children: [_jsx(SectionIntro, { innerHtml: sectionIntro }), _jsx("div", { className: "agents agents-selector", children: _jsx("div", { className: "grid", children: agents.slice(0, 4)
                        .map((agent) => (_jsx("div", { className: "col-12 col-bleed-y", children: renderAgentItem(agent) }))) }) })] }));
}
export default AgentsSelector;
